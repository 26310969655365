
.adduser-img img{
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    object-fit: cover;
    background: #dfdfdf;
    /* overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: #F2F2F2; */
}

.adduser-btn-container{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.adduser-btn{
    border-radius: 2rem;
}