.sidebar {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;
  width: 0rem;
  height: 100%;
  /* height: 100vh; */
  border-radius: 2rem;
  transition: all 0.5s;
  margin-left:-50rem
  
}
.sidebar.company{
  background-color: #0C0632;
  color: #ffffff;

}
.sidebar.agent{
  background-color: #1E4ECA;
  color:#ffffff

}


.sidebar-header{
  font-weight: 600;
}

.sidebar.open.agent{
  width: 9rem;
}
.sidebar.open.company{
  width: 20rem;
}
.active-company{
  color: #3E66CD!important
}
.active-agent{
  background-color: #7895df;
  /* color: #3E66CD!important */
}
.sidebar-items li{
  padding: 1rem;
}

.sidebar-items li a{
  font-weight: 600;
  text-decoration: none;
  color: rgb(255, 255, 255);
}
.sidebar-items li a.agent{
  font-weight: 600;
  text-decoration: none;
  color: #ffffff;
}

.sidebar-agent-logo{
  background-color:   #D9D9D9;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.sidebar-agent-links{
  width: 50px;
  color: #ffffff;
  border: 1px solid;
  border-radius: 1rem;
  padding: 0.9rem 0.9rem !important;
}

.staff-toggle{
  /* top:0; */
  right:0;
}

.link-popup{
  background-color: #686b6f;
}