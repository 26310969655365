.agent-cal {
    font-size: small;
    width: fit-content;
}

#agent-header-btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.agent-card {
    border-radius: 2rem;
    background-color: #ffffff;
    box-shadow: 1px 1px 8px #818181;
    transition: all .1s;
    width: 100%;
}

/* .agent-card:hover {
    transform: scale(1.05);
} */

.agent-card-img img{
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 1.5rem;;
    object-fit: cover;
    background: #dfdfdf;

    /* border-radius: 1.5rem;
    aspect-ratio: 1 / 1; */
}

.agent-card-btn {
    border-radius: 1rem;
}

.agent-card-btn-sm {
    font-size: 10px;
    font-weight: 500;
    border-radius: .7rem;


}

.agent-card-data {
    color: #3E66CD;
    font-weight: 500;
    font-size: 23px;
}

.agent-card-label {
    color: #8A8A8A;
    font-weight: 400;
    font-size: 12px;
}

.agent-card-name {
    color: #555555;
    font-weight: 600;
    font-size: 16px;
}

.agent-card-role {
    color: #555555;
    font-weight: 400;
    font-size: 12px;
}

.agent-modal {
    border-radius: 2rem;
}

.asign-lead-close {
    position: absolute;
    top: 0;
    right: 0;
}

.assign-table-container {
    background-color: #F8F8F8;
    border-radius: .8rem;

}

.assign-table-header {
    font-weight: 600;
    font-size: 13px;
    color: #878787;
}

.assign-table-body {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.assign-table-body::-webkit-scrollbar {
    display: none;
}

.assign-table-content {
    background-color: #ffffff;
    border-radius: .8rem;
    font-size: 13px;
    font-weight: 500;
}


.scale-2{
    /* position: relative; */
    width: 200%;
    height: 200%;
    z-index: 9999!important;
    transition: all 2s;
}