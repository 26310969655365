table.log-data tbody tr th{
    padding-right: 3rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
table.log-data{
    font-size: 1rem;
}

.log-data{
    color: #000000;
}