.login-title{
    color: #3E66CD;
    font-weight: 600;
    font-size: 45px;
}
.login-subtitle{
    font-weight: 600;
    font-size: 15px;
}
.login-text{
    border: none !important;
    box-shadow: 0 0 .4rem .1rem #d4d1d1;
}
.login-submit{
    background: linear-gradient(#133896,#3E66CD);
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    border-radius: .8rem;
}
.login-banner{
    overflow: hidden;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 20rem 0 20rem 0;
}
.password-banner{
    overflow: hidden;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 0 8rem 2rem 2rem;
}
.login-banner img{
    transform: scale(1.2);
    animation-name: mybanner1;
    animation-duration: 1s;
}
@keyframes mybanner1 {
    from {
        margin-top: -30%;
    margin-left: 10%;
    }
    to {
        margin-top: 0%;
    margin-left: 0%;
    }
  }

a{
    text-decoration: none;
    font-weight: 600;
}
.loginUser{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.name{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.015em;
}

.post{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #A6A6A6;
}

.label-titles{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #434343;
}