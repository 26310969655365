.settings-card{
    background: #ffffff;
    border-radius: 2rem;
    height:30rem
    
}


.settings-table-container {
    background-color: #F8F8F8;
    border-radius: .8rem;

}

.settings-table-header {
    font-weight: 600;
    font-size: 13px;
    color: #878787;
}

.settings-table-body {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

}

.settings-table-body::-webkit-scrollbar {
    display: none;
}

.settings-table-content {
    background-color: #ffffff;
    border-radius: .8rem;
    font-size: 13px;
    font-weight: 500;
}

.form-select{
    background-position: right 0.2rem center !important;
}

.form-select .type-dropdown{
    border-radius: 0.5rem;
    width: 13rem ;
    height: 2.6rem !important;
    color:#000000;
    padding-left: 0.8rem;
    font-weight: 500;
    font-size: 16px !important;
}

.mobile-view-dropdown{
    border-radius: 0.5rem;
    width: 22.6rem !important;
    height: 2.6rem !important;
    color:#000000;
    padding-left: 0.8rem;
    font-weight: 500;
    font-size: 14px;
}

.type-dropdown .menu{
    border-radius: 5rem !important;
    font-family: 'Poppins', Arial  !important;
    background-color: #f9f9f9 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.040rem !important;
}

.ui.toggle.checkbox .box::before, .ui.toggle.checkbox label::before{
    background-color: #db0000 !important;
    height: 1.3rem;
    width: 2.9rem;
}

.ui.toggle.checkbox label::after {
    width: 1.3rem !important;
    height: 1.3rem !important;
}

/* .ui.toggle.checkbox input:checked~.box::before, .ui.toggle.checkbox input:checked~label::before{
    background-color: #05ae00 !important;
} */

/* .ui.toggle.checkbox input:checked ~ .box::before, .ui.toggle.checkbox input:checked ~ label::before{
    background-color: #05ae00 !important;
} */

.ui.toggle.checkbox input:checked ~ label::after{
    left: 1.6rem !important
}